import React, { useEffect, useState } from "react";
import ResponsiveModal from "react-responsive-modal";
import { use100vh } from "react-div-100vh";
import "./modal-styles.scss";

const getClassNames = (displayType) => {
  if (displayType == "overflow") {
    return {
      modal: "overflowModal",
      //overlay: "overlay"
    };
  }
  return {
    modal: "expandedModal",
    overlay: "overlay",
  };
};

const Modal = ({
  render,
  title,
  isOpen,
  onClose,
  children,
  disableFocusTrap,
  disableClose = false,
  expanded,
  id,
  customHeader,
  displayType,
  ...rest
}) => {
  const classNames = getClassNames(displayType);
  const height = displayType === "overflow" ? use100vh() : null;

  return (
    <ResponsiveModal
      focusTrapped={!disableFocusTrap}
      open={isOpen}
      onClose={() => onClose()}
      center
      showCloseIcon={false}
      classNames={classNames}
      styles={{
        modal: { height: height },
      }}
      // classNames={{
      //   modal: getModalStyle(), //large ? styles.modalLg : styles.modal,
      //   overlay: getOverlayStyle(),
      //   // root: getRootStyle(styleType),
      // }}
      onActivate={() => console.log("activated")}
    >
      <div
        className="modal-content-parent"
        style={{ overflow: "hidden" }}
        id={`modal${id ? `_${id}` : ``}`}
      >
        <div className="modal-header pb-0 px-0 mx-3 _border-0">
          {customHeader ? (
            customHeader()
          ) : (
            <h5 className="modal-title mb-2 font-weight-bold">{title}</h5>
          )}
          {!disableClose && (
            <button
              tabIndex={disableFocusTrap ? "0" : "-1"}
              className="btn close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose()}
            >
              <span aria-hidden="true">×</span>
            </button>
          )}
        </div>
        <div className="modal-body _h-100">
          {/* <Div100vh>{children}</Div100vh> */}
          {children}
        </div>
      </div>
    </ResponsiveModal>
  );
};

Modal.defaultProps = {
  isOpen: false,
  title: "modal",
  expanded: false,
  onClose: () => {},
  customHeader: false,
};

export default Modal;
